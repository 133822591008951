@tailwind base;
@tailwind components;
@tailwind utilities;

html {
	box-sizing: border-box;
}
*,
*:before,
*:after {
	box-sizing: inherit;
}
/* Font imports */

@font-face {
	font-family: 'Josefin';
	src: url('./assets/fonts/JosefinSans-Regular.ttf');
}

@font-face {
	font-family: 'Josefin';
	src: url('./assets/fonts/JosefinSans-Light.ttf');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Josefin';
	src: url('./assets/fonts/JosefinSans-Medium.ttf');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Josefin';
	src: url('./assets/fonts/JosefinSans-SemiBold.ttf');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Josefin';
	src: url('./assets/fonts/JosefinSans-Bold.ttf');
	font-weight: 700;
	font-style: normal;
}

@layer components {
	.btn-primary {
		@apply bg-gold-base border-[2px] border-gold-base px-[1.5rem] py-2 rounded-[8px] text-[#FFF] mb-1 font-josefin text-[20px] transition-all w-full  flex gap-2 justify-center cursor-pointer hover:scale-[1.03] duration-300 hover:bg-gold-highlight hover:border-gold-highlight hover:shadow-lg;
	}

	.input-primary {
		@apply border border-[#33333320] text-black-base placeholder-grey-base88 p-2 rounded-md mb-1 w-full outline-none focus:border-gold-base focus:shadow-md transition-all duration-300;
	}
}

.MuiDialog-container {
	font-family: 'Josefin' !important;
}

body {
	margin: 0;
	font-size: 16px;
	font-family: 'Josefin';
}

a {
	text-decoration: none;
}

button {
	outline: none;
}

button:hover {
	cursor: pointer;
}

ul {
	list-style-type: none;
}

ul li::before {
	content: '\2022';
	color: #5e257d;
	font-weight: bold;
	display: inline-block;
	font-size: 1.5rem;
	width: 1rem;
}

.plan-card-selected {
	background: linear-gradient(133.7deg, #d6a156 6.69%, #5e257d 35.35%), linear-gradient(0deg, rgba(51, 51, 51, 0.2), rgba(51, 51, 51, 0.2));
}

::-webkit-scrollbar {
	width: 8px;
	height: 10px;
}
::-webkit-scrollbar-thumb {
	border-radius: 10px;
	background: #c2c9d2;
}
